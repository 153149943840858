import "./banner.css";

import { BannerImg } from "../../constant/ImageConstant";
import { motion } from "framer-motion";

const Banner = () => {
  return (
    <div id="banner">
      <motion.div
        initial={{ opacity: 0, x: 0 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.5, duration: 1, fade: "fadeIn" }}
        viewport={{ once: true, amount: 0 }}
      >
        <img src={BannerImg.image} alt="" className="banner-image" />
      </motion.div>
      <motion.div
      initial={{ opacity: 0, x: 200 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.3, duration: 0.5, ease: "easeIn" }}
        viewport={{ once: true, amount: 0 }}
      >
        <div className="banner-title custom-container">
          <h3>-WARNING-</h3>
          <h3>WOMEN IN WEB3 MAY BE PRESENT.</h3>
          <h3>
            IN CASE OF CONTACT, YOU MUGHT FEEL THE URGE TO SHOWER, GET A
            HAIRCUT, TOUCH GRASS AND USE STUPID PICK UP LINES. <br />
            CALL YOUR DOCTOR IF YOU FEEL LIKE SENDING A PICTURE OF YOUR WILLY TO
            A MILLY.
          </h3>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: -200 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.3, duration: 0.5, ease: "easeIn" }}
        viewport={{ once: true, amount: 0 }}
      >
        <div className="banner-line">
          <img src={BannerImg.pink_line} alt="" className="pink" />
          <img src={BannerImg.pink_mobile} alt="" className="pink-mobile" />
        </div>
      </motion.div>
    </div>
  );
};

export default Banner;
