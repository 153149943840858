import "./Term.css";

import Navbar from "../../components/navbar";
import TermsSection from "../../components/termsSection";
import Footer from "../../components/footer";

const Term = () => {
  return (
    <div id="term">
      <Navbar />
      <TermsSection />
      <Footer />
    </div>
  )
}

export default Term;