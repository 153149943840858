import "./mask.css";

import { MaskImg } from "../../constant/ImageConstant";
import { motion } from "framer-motion";

const Mask = () => {
  return (
    <div id="mask">
      <div className="custom-container">
      <motion.div
        initial={{ opacity: 0, y: 400 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.3, fade: "fadeIn" }}
        viewport={{ once: true, amount: 0 }}
      >
        <img src={MaskImg.image} alt="" className="mask-desktop" />
        <img src={MaskImg.mobile} alt="" className="mask-mobile" />
        </motion.div>
      </div>
    </div>
  );
};

export default Mask;
