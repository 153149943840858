import "./navbar.css";

import { NavbarImg } from "../../constant/ImageConstant";
import { motion } from "framer-motion";
import { HashLink } from "react-router-hash-link";

const Navbar = () => {
  return (
    <div id="navbar">
      <div className="custom-container">
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{
            ease: "easeIn",
            duration: 0.3,
            delay: 0.7,
          }}
          viewport={{ once: true, amount: 0 }}
        >
          <nav className="navbar navbar-dark navbar-expand-lg">
            <HashLink className="navbar-brand d-flex justify-content-center" smooth to="/#">
              <img
                src={NavbarImg.logo}
                className="navbar-logo"
                draggable="false"
              />
            </HashLink>

            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav m-auto">
                <li className="nav-item active">
                  <HashLink className="nav-link" smooth to="#card">
                    COLLECTION
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="#art">
                    ART
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="#mask">
                    MOODBOARD
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="#faq">
                    FAQ
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="#team">
                    TEAM
                  </HashLink>
                </li>
                <li className="nav-item">
                  <a className="nav-link connect-btn">CONNECT</a>
                </li>
              </ul>
            </div>
          </nav>
        </motion.div>
      </div>
    </div>
  );
};

export default Navbar;
