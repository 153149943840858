import "./art.css";

import { SliderImg } from "../../constant/ImageConstant";
import { motion } from "framer-motion";

const Banner = () => {
  return (
    <div id="art">
      <div className="container">
        <motion.div
          initial={{ opacity: 0, x: 200 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.2, duration: 0.3, ease: "easeIn" }}
          viewport={{ once: true, amount: 0 }}
        >
          <h1>ART</h1>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: -200 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.3, ease: "easeOut" }}
          viewport={{ once: true, amount: 0 }}
        >
          <div className="box-gradient">
            <div className="group1 marquee">
              <img src={SliderImg.item1} alt="" />
              <img src={SliderImg.item1} alt="" />
            </div>
            <div className="group2 marquee marquee-down">
              <img src={SliderImg.item2} alt="" />
              <img src={SliderImg.item2} alt="" />
            </div>
            <div className="group3 marquee">
              <img src={SliderImg.item3} alt="" />
              <img src={SliderImg.item3} alt="" />
            </div>
            <div className="group4 marquee marquee-down hidden">
              <img src={SliderImg.item4} alt="" />
              <img src={SliderImg.item4} alt="" />
            </div>
            <div className="group5 marquee hidden">
              <img src={SliderImg.item5} alt="" />
              <img src={SliderImg.item5} alt="" />
            </div>
            <div className="group6 marquee marquee-down hidden">
              <img src={SliderImg.item6} alt="" />
              <img src={SliderImg.item6} alt="" />
            </div>
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: -200 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.3, type: "spring", stiffness: 100 }}
          viewport={{ once: true, amount: 0 }}
        >
          <div className="box-gradient mobile-art">
            <div className="mobile-group1 marquee">
              <img src={SliderImg.mobile1} alt="" />
              <img src={SliderImg.mobile1} alt="" />
            </div>
            <div className="mobile-group2 marquee marquee-down">
              <img src={SliderImg.mobile2} alt="" />
              <img src={SliderImg.mobile2} alt="" />
            </div>
            <div className="mobile-group3 marquee">
              <img src={SliderImg.mobile3} alt="" />
              <img src={SliderImg.mobile3} alt="" />
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Banner;
