import "./card.css";

import { CardsImg } from "../../constant/ImageConstant";
import { motion } from "framer-motion";

const Card = () => {
  return (
    <div id="card">
      <div className="custom-container">
        <div className="row">
          <div className="item1 card-item d-flex">
            <div className="col-lg-6 col-sm-12 col-md-12">
              <motion.div
                initial={{ opacity: 0, x: -200 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.4, duration: 0.4, ease: "easeIn" }}
                viewport={{ once: true, amount: 0 }}
              >
                <img src={CardsImg.item1} alt="" />
              </motion.div>
            </div>
            <div className="col-lg-6 col-sm-12 col-md-12">
              <motion.div
                initial={{ opacity: 0, x: 200 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.4, duration: 0.4, ease: "easeIn" }}
                viewport={{ once: true, amount: 0 }}
              >
                <h1>THE STORY</h1>
                <div className="item-content">
                  <p>
                    A lifestyle brand creating experiences for the unfiltered.
                  </p>
                  <p>the raw you</p>
                  <p>the funny you</p>
                  <p>the most human you.</p>
                  <p>Trust, we're not just pmsing.</p>
                </div>
              </motion.div>
            </div>
          </div>
          <div className="item2 card-item d-flex">
            <div className="col-lg-6 col-sm-12 col-md-12">
              <motion.div
                initial={{ opacity: 0, x: -200 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2, duration: 0.3, ease: "easeIn" }}
                viewport={{ once: true, amount: 0 }}
              >
                <h1>THE IRONY</h1>
                <div className="item-content">
                  <p>
                    We sometimes realize we fucked up as a society but the
                    biggest fuck up is to capitulate.  When your pictures are
                    prettier than you, your socials happier and your therapists
                    richer, you’ve gone from a “trendy” lifestyle to simply
                    tacky. That’s the irony presented in this art collection. 
                    We don’t plan on changing the world, we just plan on using
                    this sick new technology to build a curated community of
                    unapologetically raw mfers with style who do.
                  </p>
                </div>
              </motion.div>
            </div>
            <div className="col-lg-6 col-sm-12 col-md-12">
              <motion.div
                initial={{ opacity: 0, x: 200 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2, duration: 0.3, ease: "easeIn" }}
                viewport={{ once: true, amount: 0 }}
              >
                <img src={CardsImg.item2} alt="" />
              </motion.div>
            </div>
          </div>
          <div className="item3 card-item d-flex">
            <div className="col-lg-6 col-sm-12 col-md-12">
              <motion.div
                initial={{ opacity: 0, x: -200 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2, duration: 0.3, ease: "easeIn" }}
                viewport={{ once: true, amount: 0 }}
              >
                <img src={CardsImg.item3} alt="" />
              </motion.div>
            </div>
            <div className="col-lg-6 col-sm-12 col-md-12">
              <motion.div
                initial={{ opacity: 0, x: 200 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2, duration: 0.3, ease: "easeIn" }}
                viewport={{ once: true, amount: 0 }}
              >
                <h1>THE COLLECTION</h1>
                <div className="item-content">
                  <p>
                    A fashionably curated art collection of 5555 starving models
                    bringing RIZZ to web3. Every model, outfit or accessory is
                    hand-drawn and put together by the imagination and skills of
                    talented artists and fashion addicts. The art presents a
                    simple irony, that although figures of influence may appear
                    picture perfect on social media, some are in fact suffering
                    greatly to keep up with the toxic beauty standards and
                    extreme dieting habits to achieve an unhealthy fantasy but
                    what happens when all the eyes go away…
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
        <motion.div
          initial={{ opacity: 0, y: -200 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.3, ease: "easeIn" }}
          viewport={{ once: true, amount: 0 }}
        >
          <div className="card-bottom">
            <p>
              Since we’re getting <span className="fucked">fucked</span>, left
              and right in this bear market, we’re sending SM minters a bottle
              of personal lubricant. <br/> It’s gonna be a bumpy ride but we’ll last
              anon.
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Card;
