import "./team.css";

import { TeamImg } from "../../constant/ImageConstant";
import { SocialIcons } from "../../constant/ImageConstant";
import { motion } from "framer-motion";

const Team = () => {
  const handleEmail = (flag) => {
    switch (flag) {
      case 1:
        window.location.href = "mailto:shey@starvingmodels.io";
        break;
      case 2:
        window.location.href = "mailto:rob@starvingmodels.io";
        break;
      case 3:
        window.location.href = "mailto:alex@starvingmodels.io";
        break;
      case 4:
        window.location.href = "mailto:marji@starvingmodels.io";
        break;
      case 5:
        window.location.href = "mailto:tina@starvingmodels.io";
        break;
    }
  };

  return (
    <div id="team">
      <motion.div
        initial={{ opacity: 0, y: -200 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.4, fade: "fadeIn" }}
        viewport={{ once: true, amount: 0 }}
      >
        <h1>TEAM</h1>
      </motion.div>
      <div className="team-img">
        <motion.div
          initial={{ opacity: 0, x: -200 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.2, duration: 0.4, fade: "fadeIn" }}
          viewport={{ once: true, amount: 0 }}
        >
          <img src={TeamImg.image} alt="" className="team-image" />
          <div className="hover-area">
            <div className="empty1"></div>

            <div className="shey">
              <div className="social social-item1">
                <div>
                  <a href="https://twitter.com/NematSheida" target="_blank">
                    <img src={SocialIcons.twitter} alt="" />
                  </a>
                  <a>
                    <img
                      onClick={() => handleEmail(1)}
                      src={SocialIcons.email}
                      alt=""
                    />
                  </a>
                  SHEY
                </div>
              </div>
            </div>

            <div className="rob">
              <div className="social social-item2">
                <div>
                  <a href="https://twitter.com/elevenrm" target="_blank">
                    <img src={SocialIcons.twitter} alt="" />
                  </a>
                  <a href="">
                    <img
                      onClick={() => handleEmail(2)}
                      src={SocialIcons.email}
                      alt=""
                    />
                  </a>
                  ROB
                </div>
              </div>
            </div>
            <div className="empty2"></div>

            <div className="alex">
              <div className="social social-item3">
                <div>
                  <a
                    href="https://instagram.com/amirfazelian1986?igshid=YmMyMTA2M2Y="
                    target="_blank"
                  >
                    <img src={SocialIcons.instagram} alt="" />
                  </a>
                  <a>
                    <img
                      src={SocialIcons.email}
                      alt=""
                      onClick={() => handleEmail(3)}
                    />
                  </a>
                  ALEX
                </div>
              </div>
            </div>

            <div className="empty3"></div>

            <div className="marjaneh">
              <div className="social social-item4">
                <div>
                  <a>
                    <img
                      src={SocialIcons.email}
                      alt=""
                      onClick={() => handleEmail(4)}
                    />
                  </a>
                  MARJI
                </div>
              </div>
            </div>

            <div className="tina">
              <div className="social social-item5">
                <div>
                  <a href="https://twitter.com/tintinjpeg" target="_blank">
                    <img src={SocialIcons.twitter} alt="" />
                  </a>
                  <a>
                    <img
                      src={SocialIcons.email}
                      alt=""
                      onClick={() => handleEmail(5)}
                    />
                  </a>
                  TINA
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Team;
