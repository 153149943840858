import "./footer.css";

import { FooterImg } from "../../constant/ImageConstant";
import { motion } from "framer-motion";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  return (
    <div id="footer">
      <div className="custom-container">
        <motion.div
          initial={{ opacity: 0, x: -200 }}
          whileInView={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.2, duration: 0.3, ease: "easeIn" }}
          viewport={{ once: true, amount: 0 }}
        >
          <nav className="navbar navbar-dark navbar-expand-lg">
            <HashLink smooth to="/#" className="navbar-brand d-flex justify-content-center" >
              <img
                src={FooterImg.logo}
                className="navbar-logo"
                draggable="false"
              />
            </HashLink>

            <div className="collapse navbar-collapse" id="footerNav">
              <ul className="navbar-nav m-auto">
                <li className="nav-item active">
                  <a className="nav-link">
                    COPYRIGHT © 2022-2023 STARVING MODELS
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/terms" target="_blank">TERMS AND CONDITIONS</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link">Created by tonicLABS.io</a>
                </li>
                <li>
                  <a href="https://twitter.com/STARVINGmodels" target="_blank">
                    <i className="fa fa-twitter-square footer-twitter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </motion.div>
      </div>
    </div>
  );
};

export default Footer;
