// Navbar Images
import header_logo from "../assets/image/header_logo.png";

// Banner Image
import banner_image from "../assets/image/banner/banner.png";
import banner_yellow_line from "../assets/image/banner/yellow-line.png"
import banner_pink_line from "../assets/image/banner/pink-line.png"
import banner_white_line from "../assets/image/banner/white-line.png"
import banner_pink_mobile from "../assets/image/banner/pink-mobile.png";


// Card Images
import card_item1 from "../assets/image/card/card1.png";
import card_item2 from "../assets/image/card/card2.png";
import card_item3 from "../assets/image/card/card3.png";

// Mask Images
import mask from "../assets/image/mask.png"
import mask_mobile from "../assets/image/mask-mobile.png";

// Faq Icons
import faq_arrow from "../assets/image/faq-arrow.png";

// Slider Images
import slider1 from "../assets/image/slider/slider1.png";
import slider2 from "../assets/image/slider/slider2.png";
import slider3 from "../assets/image/slider/slider3.png";
import slider4 from "../assets/image/slider/slider4.png";
import slider5 from "../assets/image/slider/slider5.png";
import slider6 from "../assets/image/slider/slider6.png";
import mobile1 from "../assets/image/slider/mobile1.png";
import mobile2 from "../assets/image/slider/mobile2.png";
import mobile3 from "../assets/image/slider/mobile3.png";

// Team Images
import team from "../assets/image/team.png";

// Social Icons
import linkedin from "../assets/image/social/linkedin.png";
import twitter from "../assets/image/social/twitter.png";
import email from "../assets/image/social/email.png";
import instagram from "../assets/image/social/instagram.png";

// Footer Images
import footer_logo from "../assets/image/footer_logo.png";


/////////////////////////////////////////////////////////////////////////

// Export Navbar Images
export const NavbarImg = {
  logo: header_logo
};

// Export Banner Images
export const BannerImg = {
  image: banner_image,
  yellow_line: banner_yellow_line,
  pink_line: banner_pink_line,
  white_line: banner_white_line,
  pink_mobile: banner_pink_mobile
};

// Export Card Images
export const CardsImg = {
  item1: card_item1,
  item2: card_item2,
  item3: card_item3,
};

// Export Mask Images
export const MaskImg = {
  image: mask,
  mobile: mask_mobile
}

// Export Faq Icon
export const FaqIcon = {
  arrow: faq_arrow
}


// Export Slider Images
export const SliderImg = {
  item1: slider1,
  item2: slider2,
  item3: slider3,
  item4: slider4,
  item5: slider5,
  item6: slider6,
  mobile1: mobile1,
  mobile2: mobile2,
  mobile3: mobile3,
}

// Team Images
export const TeamImg = {
  image: team
}

// Social Icons
export const SocialIcons = {
  linkedin: linkedin,
  twitter: twitter,
  email: email,
  instagram: instagram
}

// Footer Images
export const FooterImg = {
  logo: footer_logo
}