import "./Mint.css";

import Navbar from "../../components/navbar";
import MintSection from "../../components/mintSection";
import Footer from "../../components/footer";

const Mint = () => {
  return (
    <div id="mint">
      <Navbar />
      <MintSection />
      <Footer />
    </div>
  )
}

export default Mint;