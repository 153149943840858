import "./Home.css";
import { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";

import Loader from "../../components/Loader";
import Navbar from "../../components/navbar";
import Banner from "../../components/banner";
import Card from "../../components/card";
import Mask from "../../components/mask";
import Faq from "../../components/faq";
import Art from "../../components/art";
import Team from "../../components/team";
import Footer from "../../components/footer";

const Home = () => {
  const [Loaded, setLoaded] = useState(false);

  useEffect(() => {
    const onPageLoad = () => {
      setLoaded(true);
    };
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad, false);
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <div className="home">
      {/* <AnimatePresence><Loader /></AnimatePresence> */}
      <AnimatePresence>{Loaded ? null : <Loader />}</AnimatePresence>
      <Navbar />
      <Banner />
      <Card />
      <Mask />
      <Faq />
      <Art />
      <Team />
      <Footer />
    </div>
  );
};

export default Home;
