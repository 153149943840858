import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./pages/home/Home";
import Mint from "./pages/mint/Mint";
import Term from "./pages/term/Term";

import './App.css';

function App() {

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/mint" element={<Mint />} />
          <Route path="/terms" element={<Term />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;