import { FooterImg } from "../constant/ImageConstant";
import { motion } from "framer-motion";

function Loader() {
  return (
    <motion.div
      className="Loader"
      initial={{ y: 0, opacity: 1 }}
      exit={{ y: "-100%", opacity: 0.5 }}
      transition={{ duration: 1.3 }}
    >
      <div id="loader"></div>
      <img src={FooterImg.logo} alt="" />
      <div className="blink-text">
        <b>starving</b> models
      </div>
    </motion.div>
  );
}

export default Loader;
