import "./faq.css";
import FaqComponent from "react-faq-component";
import { motion } from "framer-motion";

const Faq = () => {
  const faqArray = {
    title: "",
    rows: [
      {
        title: "WHEN IS THE MINT DATE?",
        content: "<p>TBA</p>",
      },
      {
        title: "WHICH BLOCKCHAIN ARE THE MODELS ON?",
        content: "<p>Ethereum</p>",
      },
      {
        title: "WHAT IS THE MINT PRICE?",
        content: "<p>TBA</p>",
      },
      {
        title: "WHAT IS THE TOTAL SUPPLY?",
        content: "<p>5555</p>",
      },
      {
        title: "WILL THERE BE A PRESALE?",
        content: "<p>Private</p>",
      },
      {
        title: "COMMERCIAL RIGHTS?",
        content:
          "<p>You will have full IP and usage rights for the underlying artwork in your Starving Models NFT. Hope you deserve it.</p>",
      },
      {
        title: "WHAT IS LUBIA?",
        content:
          "<p>A water-based personal lubricant brand finally giving a shit about women’s health that aims to reimagine the sexual wellness industry for the new generation. We’re sending a bottle to every degen that mints a model as a welcome gift to the STARVING society. </p>",
      },
      {
        title: "WHY IS THE FIRST COLLABORATION WITH A LUBRICANT BRAND?",
        content: "<p>Because if you lube it, they’ll come…? </p>",
      },
      {
        title: "IS THIS A SEX THING?",
        content:
          "As a society, we’ve become more open to talking about sex and sexual health. One topic still seems to make people squirm: lube, even though it theoretically would make our sex lives a lot better. We don’t play into taboos, we dare to change culture, so it only seemed fitting.",
      },
    ],
  };

  const styles = {
    bgColor: "#1F1F1F",
    titleTextColor: "#B49176",
    rowTitleColor: "#B49176",
    rowContentColor: "#B49176",
  };

  return (
    <div id="faq">
      <div className="container">
        <motion.div
          initial={{ opacity: 0, x: -200 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.2, duration: 0.3, ease: "easeIn" }}
          viewport={{ once: true, amount: 0 }}
        >
          <h1>Faq</h1>
        </motion.div>
        <div className="faq-w">
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.3, ease: "easeIn" }}
            viewport={{ once: true, amount: 0 }}
          >
            <FaqComponent data={faqArray} styles={styles} />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
