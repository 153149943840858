import "./mint.css";

import { motion } from "framer-motion";

const MintSection = () => {
  return (
    <div id="mintSection">
      <motion.div
        initial={{ opacity: 0, x: -200 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.2, duration: 0.3, ease: "easeIn" }}
        viewport={{ once: true, amount: 0 }}
      >
        <h1>Starving? mint</h1>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: 200 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.2, duration: 0.3, ease: "easeIn" }}
        viewport={{ once: true, amount: 0 }}
      >
      <div className="mint-card">
        <div className="mint-input">
          <div>0</div>
          <div className="mintBtn">Mint</div>
        </div>
        <div className="mint-price">
          <div>Mint price: 0.08</div>
          <div>max per wallet: 5</div>
        </div>
        <div className="mint-address">
          Contact Adress:
          0xa584E25aC604E1E365802ff225f7D02eDabAA4FC
        </div>
      </div>
      </motion.div>
    </div>
  );
};

export default MintSection;
